import * as $ from 'jquery';
class HorizontalScrollerLoop {
    constructor(wrapper, prevBtn, nextBtn, itemsToShow, itemWidth) {
        this.$wrapper = $(wrapper);
        this.$list = this.$wrapper.find('ul');
        this.$prevBtn = $(prevBtn);
        this.$nextBtn = $(nextBtn);
        this.itemsToShow = itemsToShow;
        this.itemWidth = itemWidth;
        this.currentIndex = 0;
        this.totalItems = this.$list.children().length;

        this.init();
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {
        this.$prevBtn.on('click', () => this.prev());
        this.$nextBtn.on('click', () => this.next());
    }

    prev() {
        if (this.currentIndex === 0) {
            // If at the beginning, loop to the last set of visible items
            this.currentIndex = Math.max(this.totalItems - this.itemsToShow, 0);
        } else {
            this.currentIndex--;
        }
        this.updatePosition();
    }

    next() {
        if (this.currentIndex >= this.totalItems - this.itemsToShow) {
            // If at the end, loop back to the start
            this.currentIndex = 0;
        } else {
            this.currentIndex++;
        }
        this.updatePosition();
    }

    updatePosition() {
        const translateX = -this.currentIndex * this.itemWidth;
        this.$list.css('transform', `translateX(${translateX}px)`);
    }
}

class HorizontalScroller {
    constructor(wrapper, prevBtn, nextBtn, itemsToShow, itemWidth) {
        this.$wrapper = $(wrapper);
        this.$list = this.$wrapper.find('ul');
        this.$prevBtn = $(prevBtn);
        this.$nextBtn = $(nextBtn);
        this.itemsToShow = itemsToShow;
        this.itemWidth = itemWidth;
        this.currentIndex = 0;
        this.totalItems = this.$list.children().length;

        this.init();
    }

    init() {
        this.updateButtonsState();  // Disable prev button initially
        this.bindEvents();
    }

    bindEvents() {
        this.$prevBtn.on('click', () => this.prev());
        this.$nextBtn.on('click', () => this.next());
    }

    prev() {
        if (this.currentIndex > 0) {
            this.currentIndex--;
            this.updatePosition();
        }
        this.updateButtonsState();
    }

    next() {
        if (this.currentIndex < this.totalItems - this.itemsToShow) {
            this.currentIndex++;
            this.updatePosition();
        }
        this.updateButtonsState();
    }

    updatePosition() {
        const translateX = -this.currentIndex * this.itemWidth;
        this.$list.css('transform', `translateX(${translateX}px)`);
    }

    updateButtonsState() {
        // Disable the prev button if at the beginning
        if (this.currentIndex === 0) {
            this.$prevBtn.prop('disabled', true);
        } else {
            this.$prevBtn.prop('disabled', false);
        }

        // Disable the next button if at the end
        if (this.currentIndex >= this.totalItems - this.itemsToShow) {
            this.$nextBtn.prop('disabled', true);
        } else {
            this.$nextBtn.prop('disabled', false);
        }
    }
}

// Exported function to initialize the horizontal scroller
export const bootSlideListComponent = ({ wrapperSelector, prevBtnSelector, nextBtnSelector, itemsToShow = 10, itemWidth = 96 }) => {
    $(document).ready(function() {
        new HorizontalScroller(wrapperSelector, prevBtnSelector, nextBtnSelector, itemsToShow, itemWidth);
    });
};