import * as $ from 'jquery';
import './PassiveListeners';
import { bootSlideListComponent } from "./SlideList";
import {bootFAQComponent} from "./common";
/*import './owlSliders';
import {
	bootTrigger,
	bootVisit,
	bootLightbox,
	bootCopyCode,
	bootShowMoreBtn,
	bootReadMoreBtn,
	bootReadMoreCommentBtn,
	bootClosePopupBtn,
	bootRangeToolTip,
	bootSticky,
	bootTOCList,
	bootShowLessBtn,
	bootCasinoSelectPopup,
	bootHideCasinoSelectPopup,
	bootCloseCasinoSelectPopup
} from "./common";*/
//import './CommentsFilter';
import bootCookie from './cookies';
//import './FormHandlers';
//import './BonusVoting';
//import './CasinoCountriesTab';
//import './CasinosLoadMore';
//import './LoadMoreComments';
//import './PaginateComments';
//import './CountrySelect';

//bootLightbox();
//bootCopyCode();
//bootTrigger();
//bootVisit();
//bootShowMoreBtn();
//bootReadMoreBtn();
//bootReadMoreCommentBtn();
//bootClosePopupBtn();
//bootRangeToolTip();
//bootSticky();
//bootTOCList();
//bootShowLessBtn();
//bootCookie();
//bootCasinoSelectPopup();
//bootHideCasinoSelectPopup();
//bootCloseCasinoSelectPopup();
bootFAQComponent();
// Boot the horizontal scroller component
bootSlideListComponent({
	wrapperSelector: '.slide-list-js',
	prevBtnSelector: '.slide-list-prev-js',
	nextBtnSelector: '.slide-list-next-js',
	itemsToShow: 10,
	itemWidth: 96
});


$(document).ready(function(){
    "use strict";
	console.log("Landing js works");



	$('.js-cta').on('click',function(){
		console.log('Redirecting');
		window.location.href = $(this).data('href');
	});

	$('body').on('click','.js-show-more-items',function(){
		const $parentBlock = $(this).parent();
		const $this = $(this);
		const itemType = $this.data('item-type');
		$parentBlock.find($(`.${itemType}-item`)).slideDown();
		$parentBlock.find($(`.${itemType}-separator`)).show();
		$this.hide();
	});
	$(".trigger").on('click',function () {
		$('.main-menu').slideToggle();
		$('html,body').toggleClass("shadow");
	});

	// slide list





});
